import { Checkbox, Collapse, FormControlLabel } from "@mui/material";
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { t } from "i18next";
import { useEffect, useState } from "react";
import AnimationIcon from "../components/Generics/AnimationIcon.jsx";
import { useStateContext } from "../contexts/ContextProvider.js";
import { auth, storage } from "../firebase.config.js";
import { updateFirestoreDoc } from "../helper/functions.js";
import { Delete, Dot } from "../helper/icons.js";

const Settings = () => {
  const { customer } = useStateContext();

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  const updateSetting = async (type) => {
    const newData = {
      settings: {...customer?.settings, [type]: !customer?.settings[type] },
    };
    await updateFirestoreDoc("customers", auth.currentUser.uid, newData);
  };

  const deleteFile = async (type) => {
    await updateFirestoreDoc("customers", auth.currentUser.uid, { [type]: "" });
    const fileRef = ref(storage, `customers/${auth.currentUser.uid}/${type === "comissionContractLink" ? "comissionContract" : "privacyContract"}`);
    deleteObject(fileRef).then(() => {
      // File deleted successfully
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
  };

  return (
    <div className="w-screen flex flex-col items-center mt-5 smm:mt-10 mb-20">
      <div className="flex flex-col w-full ssm:w-3/4 md:w-2/3 lg:w-1/2 px-1 sm:px-1.5 py-1">
        <p className="w-full sm:text-xl text-allimmoDark sm:mb-1">
          {t("Einstellungen.Allgemein")}
        </p>
        <div className="p-3 ssm:p-5 border border-allimmoDark/80 flex flex-col rounded-lg w-full mb-3 bg-allimmoDark/10">
          <FormControlLabel
            control={
              <Checkbox
                value={customer?.settings?.emails}
                checked={customer?.settings?.emails}
                onChange={() => updateSetting("emails")}
              />
            }
            label={
              <p className="text-sm sm:text-base">
                {t("Einstellungen.KeineBenachrichtigungen")}
              </p>
            }
          />

          <FormControlLabel
            control={
              <Checkbox
                value={customer?.settings?.marketing}
                checked={customer?.settings?.marketing}
                onChange={() => updateSetting("marketing")}
              />
            }
            label={
              <p className="text-sm sm:text-base">
                {t("Einstellungen.KeinMarketing")}
              </p>
            }
          />

          <FormControlLabel
            control={
              <Checkbox
                value={customer.settings?.cookies}
                checked={customer.settings?.cookies}
                onChange={() => updateSetting("cookies")}
              />
            }
            label={
              <p className="text-sm sm:text-base">
                {t("Einstellungen.CookiesErlauben")}
              </p>
            }
          />
          
        </div>

        {/* <p className="w-full sm:text-xl text-allimmoDark sm:mb-1">
          {t("Einstellungen.Objects")}
        </p>
        <div className="p-3 ssm:p-5 border border-allimmoDark/80 flex flex-col rounded-lg w-full mb-3 bg-allimmoDark/10">
          <p>Auto-Kontakt</p>
          <p className="text-xs italic">Wenn eines Deiner Objekte mit einer Suche matched, wird der Suchende, vorausgesetzt du hast genug Credits in deinem Account, automatisch über die Allimmo Match Plattform kontaktiert.</p>
          <FormControlLabel
            control={
              <Checkbox
                value={customer?.settings?.autoContact ?? false}
                checked={customer?.settings?.autoContact ?? false}
                onChange={() => updateSetting("autoContact")}
              />
            }
            label={
              <p className="text-sm sm:text-base">
                {t("Einstellungen.AutoKontaktFürAlleObjekte")}
              </p>
            }
          />
        </div> */}

        <p className="w-full sm:text-xl text-allimmoDark sm:mb-1">
          {t("Einstellungen.FürMakler")}
        </p>
        <div className="p-3 border border-allimmoDark/80 flex flex-col mb-3 rounded-lg w-full gap-2 bg-allimmoDark/10">
          <div className="flex flex-col">
            <div className="flex flex-row items-center gap-1">
            <Dot size={15} className="text-allimmoDark"/>
              
              {customer?.privacyLink !== "" ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="hover:underline cursor-pointer text-allimmoDark text-sm ssm:text-base grow"
                  href={customer?.privacyLink}
                >
                  {t("Einstellungen.DatenschutzAnsehen")}
                </a>
              ) : (
                <p className="text-xs ssm:text-sm">
                  {t("Einstellungen.KeinDatenschutz")}
                </p>
              )}

              {customer?.privacyLink !== "" && (
                <Delete
                  onClick={() => deleteFile("privacyLink")}
                  size={15}
                  className="text-red-500 cursor-pointer hover:text-red-700 active:text-red-500"
                />
              )}
            </div>

            <div className="flex flex-row gap-1 items-center">
              <Dot size={15} className="text-allimmoDark"/>
              
              {customer?.comissionContractLink !== "" ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="hover:underline cursor-pointer text-allimmoDark text-sm ssm:text-base grow"
                  href={customer?.comissionContractLink}
                >
                  {t("Einstellungen.VereinbarungAnsehen")}
                </a>
              ) : (
                <p className="text-xs ssm:text-sm">
                  {t("Einstellungen.KeineVereinbarung")}
                </p>
              )}
              {customer?.comissionContractLink !== "" && (
                <Delete
                  onClick={() => deleteFile("comissionContractLink")}
                  size={15}
                  className="text-red-500 cursor-pointer hover:text-red-700 active:text-red-500"
                />
              )}
            </div>
          </div>
          {/* <PrivacyUpload /> */}
          {/* <ContractUpload
            header={t("Einstellungen.VereinbarungUpload")}
            uploadType="default"
          /> */}
          <FileUploader title={t('Einstellungen.DatenschutzUpload')} contractType="privacyContract" selectLabel={"Datei auswählen"} linkname="privacyLink" acceptedFileTypes=".pdf, .doc, .docx"/>
          <FileUploader title={t('Einstellungen.VereinbarungUpload')} contractType="comissionContract" selectLabel={"Datei auswählen"} linkname="comissionContractLink" acceptedFileTypes=".pdf, .doc, .docx"/>
        </div>

        {/* <p className="w-full sm:text-xl text-allimmoDark sm:mb-1">
          {t("Einstellungen.Affiliate")}
        </p>
        <div className="p-5 border border-allimmoDark/80 flex flex-col rounded-lg w-full bg-allimmoDark/10">
          <FormControlLabel 
          control={
            <Checkbox
              value={customer?.settings?.affiliate}
              checked={customer?.settings?.affiliate}
              onChange={() => updateSetting("affiliate")}
            />}
          label={t('Einstellungen.AffiliateLabel')}
          />
          {customer?.settings?.affiliate && <p className="italic text-sm text-allimmoDark">{t('Einstellungen.AffiliateInfo')}</p>}
        </div> */}
      </div>
    </div>
  );
};

const FileUploader = ({selectLabel, acceptedFileTypes, contractType, title, linkname, _type = "file"}) => {

  const { screenSize } = useStateContext();

  const [progressPercent, setProgressPercent] = useState(0);
  const [exposeError, setExposeError] = useState(false);
  const [exposeLink, setExposeLink] = useState("");
  const [file, setFile] = useState(null);
  const [finished, setFinished] = useState(false);

  const handleFileSubmit = () => {
    if (!file) return;
    if (file.size > 1024 * 1024 * 5) {
      alert(t("Upload.DateiZuGroß"));
      return;
    }

    if (
      ![
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ].includes(file.type)
    ) {
      setExposeError(true);
      return;
    }
    setFinished(false);
    setExposeError(false);

    const storageRef = ref(storage, `customers/${auth.currentUser.uid}/${contractType}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgressPercent(progress);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          setExposeLink(downloadURL);

          // expose Link to DB
          await updateFirestoreDoc("customers", auth.currentUser.uid, {
            [linkname]: downloadURL,
          });

          setTimeout(() => {
            setFile(null);
            setProgressPercent(0);
            setFinished(true);
          }, "500");
        });
      }
    );
  };

  return (
    <div className="flex-flex-col justify-center items-center w-full">
      <p className="text-xs ssm:text-sm md:text-base -mb-1 mt-1">{title}</p>
      <Collapse className="w-full flex flex-col justify-center items-center" in={file !== null}>
          <div className=" w-full bg-white py-2 pl-2 pr-1 rounded border border-orange-500 border-dashed flex flex-row justify-center items-center gap-1.5">
            <AnimationIcon animate _color={false} _iconSize={40} iconSrc={require(`../assets/animations/objectCreation/doc.json`)}/>
            {file !== null && (
              <div className="flex flex-col justify-center grow">
                <p className="text-allimmoDark grow"><a target='_blank' href={URL.createObjectURL(file)}>
                  {file?.name.substring(
                    0,
                    screenSize.width < 390
                      ? 20
                      : screenSize.width < 390
                      ? 25
                      : 30
                  ) + "..."}</a>
                </p>
                <p className="text-orange-500 italic -mt-1 text-sm">
                  {(file?.size / 1024 ** 2).toFixed(2)} MB
                </p>
              </div>
            )}
            <p
              className="text-2xl text-red-400 cursor-pointer hover:text-red-300 active:text-red-400 w-6 text-center"
              onClick={() => setFile(null)}
            >
              x
            </p>
          </div>
      </Collapse>
      <div className="flex h-12 flex-row items-center justify-center w-full gap-1 mt-1">
        <input
          id="upload"
          hidden
          onChange={(e) => setFile(e.target?.files[0])}
          type={_type}
          accept={acceptedFileTypes}
        />
        <label
          className="w-full flex h-12 flex-row items-center gap-1 rounded bg-white border-allimmoDark border p-2 cursor-pointer hover:bg-slate-100"
          htmlFor="upload">
            <p className="text-center w-full text-allimmoDark text-sm ssm:text-lg">{selectLabel}</p>
        </label>
        {file !== null && progressPercent === 0 && (
            <button onClick={handleFileSubmit} className="w-full flex h-12 flex-row items-center gap-1 rounded bg-white border-allimmoDark border p-2 cursor-pointer hover:bg-slate-100">
              <p className="text-center w-full text-allimmoDark text-sm ssm:text-lg">
                Datei hochladen
              </p>
            </button>
          )}
      </div>
    </div>
  )
}

export default Settings;
