import { useState } from 'react';
import { convertShortTimestamp, hideString } from '../../helper/functions';
import { useStateContext } from '../../contexts/ContextProvider';
import { Checkbox, Collapse } from '@mui/material';
import { t } from 'i18next';
import { EyeOff } from '../../helper/icons';

const Prospect = ({data, select, selection, index}) => {

    const { screenSize } = useStateContext();
    const [expanded, setExpanded] = useState(false);
    const additionalInfo = (data?.urgency === "urgent" || data?.searchOrder);

    const handleAdditionalInfoOpen = (e) => {
        e.stopPropagation()
        setExpanded(!expanded);
    };

    const handleVisibility = (e) => {
        e.stopPropagation()
        console.log('Hide')
    };

    return (
        <div onClick={() => select(data.id)} className={`flex flex-col w-full rounded border active:bg-slate-50 pl-3 py-0.5 ${index !== 0 ? 'mt-1' : ''} border-allimmoDark bg-white`}>
            <div className={`flex flex-row items-center w-full`}>
                <p className={`mr-2 sm:mr-3 text-allimmoDark`} size={18}>{index + 1}</p>
                <p className='grow'>{hideString(data?.userName, 2)}</p>
                {screenSize.width > 500 && <p className='mr-2'>{convertShortTimestamp(data?.created.toDate())}</p>}
                {additionalInfo &&
                <div className='flex flex-row items-center px-1 rounded bg-allimmoDark/5' onClick={(e) => handleAdditionalInfoOpen(e)}>
                    {data?.urgency === "urgent" && <img width={25} src={require('../../assets/turkey-med.gif')} alt=""/>}
                    {(data?.searchOrder && data?.searchOrder !== undefined) && <img width={25} src={require('../../assets/smile.gif')} alt=""/>}
                    <p>...</p>
                </div>}
                <Checkbox 
                    size='small'
                    disableRipple
                    disableTouchRipple
                    value={selection.includes(data.id)} 
                    checked={selection.includes(data.id)} 
                    onChange={() =>{} }
                />
                {/* <EyeOff size={20} className='cursor-pointer text-allimmoDark/20 hover:text-allimmoDark/80 mr-2 -ml-0.5' onClick={(e) => handleVisibility(e)}/> */}
                
            </div>
            <Collapse className={`flex flex-col ${expanded ? 'my-3' : ''}`} in={expanded}>
                {data?.urgency === "urgent" &&
                <div className='flex flex-row space-x-2 items-center'>
                    <img width={30} src={require('../../assets/turkey-med.gif')} alt=""/>
                    <p className='text-allimmoDark'>{`${t("ObjectDetails.Urgent")}`}</p>
                </div>}

                {(data?.searchOrder && data?.searchOrder !== undefined) &&
                <div className='flex flex-row space-x-2 items-center'>
                    <img width={30} src={require('../../assets/smile.gif')} alt=""/>
                    <p className='text-allimmoDark'>{`${t("ObjectDetails.SearchOrder")}`}</p>
                </div>}
            </Collapse>
        </div>
    )
};

export default Prospect
